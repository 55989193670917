/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, Typography } from '@mui/material';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Icon from '@/assets/icons/icon_dangerous.svg';
import notFound from '@/assets/images/not-found.svg';
import Page from '@/components/page';
import { SectionWrapper } from '@/styles/styled';

export default function Page404() {
  const { t } = useTranslation(['common', 'messages']);

  return (
    <Page error title="404 Page Not Found" sx={{ height: 1 }}>
      <SectionWrapper
        sx={{
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 8,
          py: 4,
        }}
      >
        <Image src={notFound} alt="not found" width={400} height={400} />
        <Typography
          variant="h3"
          fontWeight={900}
          sx={{
            textAlign: 'center',
            fontSize: { xs: '24px', md: '34px' },
            display: 'flex',
            alignItems: 'center',
          }}
          paragraph
          mt={1}
        >
          <Image src={Icon} alt="" style={{ margin: '0 10px' }} />{' '}
          {t('not_found', { ns: 'messages' })}
        </Typography>
        <Typography sx={{ color: 'text.secondary', maxWidth: 560, textAlign: 'center' }}>
          {t('not_found_page_description', { ns: 'messages' })}
        </Typography>
        <Box mt={2} display="flex" gap={1}>
          <Button
            sx={{
              color: '#fff',
              background: '#1C9AD6',
              width: { xs: 140, md: 220 },
              textTransform: 'inherit',
            }}
            href="/"
          >
            {t('go_home', { ns: 'messages' })}
          </Button>
          <Button
            sx={{
              color: '#fff',
              background: '#1C9AD6',
              width: { xs: 140, md: 220 },
              textTransform: 'inherit',
            }}
            href="/contact-us"
          >
            {t('contact_us', { ns: 'messages' })}
          </Button>
        </Box>
      </SectionWrapper>
    </Page>
  );
}

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['header', 'footer', 'common', 'messages'])),
    },
  };
};
